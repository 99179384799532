<template id="switch-button" data-cy="switchButton">
  <div class="switch-button-control">
    <div class="switch-button" :class="{ enabled: isEnabled }" @click="toggle" :style="{ '--color': color }">
      <div class="button"></div>
    </div>
  </div>
</template>

<script>
export default {
  template: "#switch-button",
  model: {
    prop: "isEnabled",
    event: "toggle"
  },
  props: {
    isEnabled: Boolean,
    color: {
      type: String,
      required: false,
      default: "#4D4D4D"
    }
  },
  methods: {
    toggle: function () {
      this.$emit("toggle", !this.isEnabled);
    }
  }
};
</script>
