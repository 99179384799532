<template>
  <div class="imageUploader grey mb-3 rounded-lg" :class="dragOver ? 'lighten-1' : 'lighten-3'">
    <!-- slot for parent component to activate the file changer -->
    <div
      @click="launchFilePicker()"
      @drop.prevent="drop"
      @dragover.prevent="dragOverEvent"
      @dragleave="dragLeaveEvent"
      style="width: 100%; height: 100%; padding: 10px"
    >
      <slot name="activator"></slot>
    </div>
    <div>
      <slot name="nonActivator"></slot>
    </div>
    <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
    <input
      type="file"
      multiple
      ref="file"
      :name="uploadFieldName"
      @change="onFileChange($event.target.name, $event.target.files)"
      style="display: none"
    />
    <!-- error dialog displays any potential error messages -->
    <v-dialog v-model="errorDialog" max-width="300">
      <div class="addToCartBox">
        <img src="@/assets/zeplin/question-1.svg" alt="" />
        <!-- <h3>{{ title }}</h3> -->
        <p>{{ errorText }}</p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed dark rounded x-large class="error" @click="errorDialog = false">
            {{ this.$t("dialogButton.confirm") }}
          </v-btn>
        </v-card-actions>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "image-input",
  data: () => ({
    errorDialog: null,
    errorText: "",
    uploadFieldName: "file",
    maxSize: 32768,
    dragOver: false
  }),
  props: {
    // Use "value" to enable using v-model
    value: Object,
    fileLimit: {
      type: Number
    }
  },
  methods: {
    launchFilePicker() {
      this.$refs.file.click();
    },
    onFileChange(fieldName, file) {
      const { maxSize } = this;
      if (file.length > this.fileLimit) {
        this.$emit("exceedLimit");
        return;
      }
      for (let i = 0; i < file.length; i++) {
        let imageFile = file[i];
        if (file.length > 0) {
          let size = imageFile.size / maxSize / maxSize;
          const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
          if (!acceptedImageTypes.includes(imageFile.type)) {
            // check whether the upload is an image
            this.errorDialog = true;
            this.errorText = this.$t("errorMsg.newArtwork.imageFormat");
            break;
          } else if (size > maxSize) {
            // check whether the size is greater than the size limit
            this.errorDialog = true;
            this.errorText = "Your file is too big! Please select an image under 32MB";
            break;
          } else {
            // Append file into FormData and turn file into image URL
            let formData = new FormData();
            let imageURL = URL.createObjectURL(imageFile);
            formData.append(fieldName, imageFile);

            // Emit the FormData and image URL to the parent component
            this.$emit("input", { formData, imageURL, size: imageFile.size });
          }
        }
      }
    },
    drop(event) {
      this.dragOver = false;
      event.preventDefault();

      this.onFileChange("file", event.dataTransfer.files);
    },
    dragOverEvent() {
      this.dragOver = true;
    },
    dragLeaveEvent() {
      this.dragOver = false;
    }
  }
};
</script>
