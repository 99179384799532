<template>
  <div class="licenseContainer" v-if="sellerPreference">
    <message-box
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
      :allowCancel="messageBox.allowCancel"
    />
    <div id="licenseHeading">
      <div>
        <h5 class="ma-0">
          {{ $t("artworkSellerPreference") }}
        </h5>
      </div>
      <v-tooltip v-model="show" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small class="ml-2" v-bind="attrs" v-on="on"> mdi-alert-circle-outline </v-icon>
        </template>
        <span>{{ $t("artworkSellerPreferenceInfo") }}</span>
      </v-tooltip>
    </div>
    <div id="license">
      <div style="padding-bottom: 20px">
        <v-radio-group
          v-model="sellerPreference.orderType"
          row
          hide-details=""
          @change="changeSellerPreference"
          class="pa-0 mt-2"
        >
          <v-radio :label="viewSellerType('negotiable')" value="negotiable" style="min-width: 130px" />
          <v-radio :label="viewSellerType('sell')" value="sell" style="min-width: 130px" />
          <v-radio :label="viewSellerType('license')" value="license" style="min-width: 130px" />
          <v-radio :label="viewSellerType('notForSale')" value="notForSale" style="min-width: 130px" />
        </v-radio-group>
      </div>
      <div v-if="sellerPreference.orderType === 'sell' || sellerPreference.orderType === 'license'">
        <v-currency-field v-model="sellerPreference.price" :outlined="true" prefix="US$" />
      </div>
      <div v-if="sellerPreference.orderType === 'license'" class="mb-3">
        <p class="pa-0 ma-0">
          {{
            $t("viewSellerLicense", {
              number: this.sellerPreference.duration
            })
          }}
        </p>
        <v-select
          v-model="sellerPreference.duration"
          :items="numYears"
          item-text="translation"
          item-value="value"
          outlined
          dense
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import VCurrencyField from "../VCurrencyField.vue";
import MessageBox from "../Common/MessageBox.vue";
export default {
  props: ["sellerPreference"],
  components: {
    VCurrencyField,
    MessageBox
  },
  data: () => ({
    editing: false,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    show: false
  }),

  methods: {
    viewSellerType(type) {
      const result = this.translateArray("viewSellerType");
      switch (type) {
        case "negotiable":
          return result[0];
        case "sell":
          return result[1];
        case "license":
          return result[2];
        case "notForSale":
          return result[3];
        default:
          return result[0];
      }
    },
    translateArray(item) {
      const content = this.$t(item);
      const result = content.replace(/['"]+/g, "").replace(/['“]+/g, "").replace(/['”]+/g, "").split(",");
      return result;
    },
    changeSellerPreference() {
      // Lock user from sending another seller preference update in 30 seconds
      if (this.sellerPreference.orderType === "negotiable") {
        this.sellerPreference.duration = null;
        this.sellerPreference.price = null;
      }
      if (this.sellerPreference.orderType === "sell") {
        this.sellerPreference.duration = null;
      }
      if (this.sellerPreference.orderType === "notForSale") {
        this.sellerPreference.duration = null;
        this.sellerPreference.price = null;
      }
      this.$emit("update:sellerPreference", this.sellerPreference);
    }
  },
  computed: {
    numYears() {
      let years = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
      years = years.map((elem) => {
        return {
          translation: this.$t("viewSellerLicenseYear", { number: elem }),
          value: elem
        };
      });
      return years;
    }
  }
};
</script>

<style></style>
