<template>
  <div v-if="item.loading" style="display: flex; align-items: center; flex-direction: column">
    <div
      style="
        min-height: 150px;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      "
    >
      <flower-spinner v-if="indicator === 0" :animation-duration="2500" :size="size" :color="color" />
      <pixel-spinner v-if="indicator === 1" :animation-duration="2000" :size="size" :color="color" />
      <hollow-dots-spinner
        v-if="indicator === 2"
        :animation-duration="1000"
        :dot-size="15"
        :dots-num="3"
        :color="color"
      />
      <intersecting-circles-spinner v-if="indicator === 3" :animation-duration="1200" :size="size" :color="color" />
      <orbit-spinner v-if="indicator === 4" :animation-duration="1200" :size="size" :color="color" />
      <radar-spinner v-if="indicator === 5" :animation-duration="2000" :size="size" :color="color" />
      <scaling-squares-spinner v-if="indicator === 6" :animation-duration="1250" :size="size" :color="color" />
      <half-circle-spinner v-if="indicator === 7" :animation-duration="1000" :size="size" :color="color" />
      <trinity-rings-spinner v-if="indicator === 8" :animation-duration="1500" :size="size" :color="color" />
      <fulfilling-square-spinner :animation-duration="4000" :size="50" :color="color" v-if="indicator === 9" />
      <circles-to-rhombuses-spinner
        :animation-duration="1200"
        :circles-num="3"
        :circle-size="15"
        :color="color"
        v-if="indicator === 10"
      />
      <semipolar-spinner :animation-duration="2000" :size="size" :color="color" v-if="indicator === 11" />
      <self-building-square-spinner :animation-duration="6000" :size="size" :color="color" v-if="indicator === 12" />
      <swapping-squares-spinner :animation-duration="1000" :size="size" :color="color" v-if="indicator === 13" />
      <fulfilling-bouncing-circle-spinner
        :animation-duration="4000"
        v-if="indicator === 14"
        :size="size"
        :color="color"
      />
      <fingerprint-spinner :animation-duration="1500" :size="64" :color="color" v-if="indicator === 15" />
      <spring-spinner :animation-duration="3000" :size="size" :color="color" v-if="indicator === 16" />
      <atom-spinner :animation-duration="1000" :size="size" :color="color" v-if="indicator === 17" />
      <looping-rhombuses-spinner :animation-duration="2500" :rhombus-size="15" :color="color" v-if="indicator === 18" />
      <breeding-rhombus-spinner :animation-duration="2000" :size="size" :color="color" v-if="indicator === 19" />
    </div>
    <h3 class="mt-8" style="color: #63a3c3">{{ item.message }}</h3>
    <p class="mt-2" style="color: #63a3c3">{{ item.subtitle }}</p>
  </div>
</template>

<script>
import {
  FlowerSpinner,
  PixelSpinner,
  HollowDotsSpinner,
  IntersectingCirclesSpinner,
  OrbitSpinner,
  RadarSpinner,
  ScalingSquaresSpinner,
  HalfCircleSpinner,
  TrinityRingsSpinner,
  FulfillingSquareSpinner,
  CirclesToRhombusesSpinner,
  SemipolarSpinner,
  SelfBuildingSquareSpinner,
  SwappingSquaresSpinner,
  FulfillingBouncingCircleSpinner,
  FingerprintSpinner,
  SpringSpinner,
  AtomSpinner,
  LoopingRhombusesSpinner,
  BreedingRhombusSpinner
} from "epic-spinners";
export default {
  components: {
    FlowerSpinner,
    PixelSpinner,
    HollowDotsSpinner,
    IntersectingCirclesSpinner,
    OrbitSpinner,
    RadarSpinner,
    ScalingSquaresSpinner,
    HalfCircleSpinner,
    TrinityRingsSpinner,
    FulfillingSquareSpinner,
    CirclesToRhombusesSpinner,
    SemipolarSpinner,
    SelfBuildingSquareSpinner,
    SwappingSquaresSpinner,
    FulfillingBouncingCircleSpinner,
    FingerprintSpinner,
    SpringSpinner,
    AtomSpinner,
    LoopingRhombusesSpinner,
    BreedingRhombusSpinner
  },
  props: {
    item: {
      type: Object
    },
    color: {
      type: String,
      default: "#63a3c3"
    },
    size: {
      type: Number,
      default: 120
    }
  },
  data: () => ({
    indicator: null
  }),
  mounted() {
    this.indicator = Math.floor(Math.random() * 19);
  },
  computed: {
    message() {
      return this.item.message;
    }
  },
  watch: {
    message: function () {
      this.indicator = Math.floor(Math.random() * 19);
    }
  }
};
</script>

<style></style>
