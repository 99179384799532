<template>
  <div id="displayCard">
    <message-box
      v-model="messageBox.state"
      :message-title="messageBox.title"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <confirm-dlg ref="confirm" />
    <div style="width: 100%">
      <v-card
        class="addArtwork"
        :style="{ padding: $screen.width > 600 ? '32px' : '16px' }"
        v-if="!loading && !workUploadState.loading"
        @drop.prevent
        @dragover.prevent
      >
        <h2 style="padding-bottom: 30px" v-if="!notNewItem">{{ this.$t("createHeading") }}</h2>
        <h2 style="padding-bottom: 30px" v-else>{{ this.$t("createHeading2") }}</h2>

        <v-row>
          <v-col cols="12" md="3">
            <image-uploader
              v-model="newItem.coverPhotos[newItem.coverPhotos.length]"
              v-if="!notNewItem"
              :fileLimit="coverPhotoLimit - newItem.coverPhotos.length"
              @exceedLimit="exceedCoverPhotoLimit"
              style="flex: 1"
              ref="imageUploader"
              v-ripple
            >
              <div slot="activator" id="uploadRectangle">
                <div
                  style="flex: 1; display: flex; align-items: center; justify-content: center; flex-direction: column"
                >
                  <img src="@/assets/zeplin/up-arrow.svg" alt="" height="31px" width="31px" />
                  <h5>{{ this.$t("createCover") }}</h5>
                </div>
                <p class="ma-0">
                  {{ this.$t("createImageUploaderHint") }}
                </p>
              </div>
            </image-uploader>
            <v-expand-transition style="max-width: 100%">
              <div
                style="max-width: 100%; overflow: scroll; padding: 10px 5px 10px 5px"
                class="grey rounded-lg"
                :class="dragOver ? 'lighten-1' : 'lighten-3'"
                v-if="newItem.coverPhotos[0]"
                @drop.prevent="dragOverAdditionalCoverPhoto"
                @dragover.prevent="dragOver = true"
                @dragleave.prevent="dragOver = false"
              >
                <transition-group name="slide" style="display: flex">
                  <v-card
                    flat
                    v-for="(item, i) in newItem.coverPhotos"
                    :key="item.imageURL"
                    class="rounded-lg ml-1 mr-1"
                    @click="imageEditDialog(i)"
                  >
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }"
                        ><v-img
                          :src="item.imageURL"
                          :height="80"
                          :width="80"
                          class="rounded-lg"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-overlay v-if="i === newItem.keyPhoto" absolute>
                            <v-icon>mdi-star</v-icon>
                          </v-overlay></v-img
                        ></template
                      >
                      <v-list>
                        <v-list-item @click="setKeyPhoto(i)">
                          <v-list-item-title>{{ $t("createImageSetKeyPhoto") }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="removeCoverPhoto(i)">
                          <v-list-item-title>{{ $t("collectionDelete") }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card>
                </transition-group>
              </div>
            </v-expand-transition>

            <div class="imageViewer" v-if="notNewItem">
              <div v-if="editedItem.coverPhotos[viewImageIndex]" style="width: 100%">
                <ImageFromFileDescriptor
                  :fileDescriptor="editedItem.coverPhotos[viewImageIndex]"
                  :key="editedItem.coverPhotos[viewImageIndex].fileName"
                ></ImageFromFileDescriptor>
              </div>
              <div v-if="editedItem.coverPhotos.length > 1" style="width: 100%">
                <v-slide-group v-model="viewImageIndex" show-arrows class="my-4" mandatory light style="width: 100%">
                  <v-slide-item v-for="i in editedItem.coverPhotos.length" :key="i" v-slot="{ active, toggle }">
                    <v-expand-transition>
                      <v-card
                        :class="i < editedItem.coverPhotos.length ? 'mr-2' : ''"
                        height="80"
                        width="80"
                        flat
                        @click="toggle"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          border-radius: 10px;
                          overflow: hidden;
                        "
                      >
                        <ImageFromFileDescriptor
                          :fileDescriptor="editedItem.coverPhotos[i - 1]"
                          :key="editedItem.coverPhotos[i - 1].fileName"
                          height="80"
                          width="80"
                        >
                          <v-overlay v-if="active" absolute> <v-icon>mdi-star</v-icon> </v-overlay>
                        </ImageFromFileDescriptor>
                      </v-card>
                    </v-expand-transition>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </div>
          </v-col>

          <v-col class="artworkInput" style="padding-top: 30px">
            <v-row class="artworkTitle" cols="12" style="padding-left: 12px">
              <h1>
                <input
                  v-model="newItem.title"
                  :placeholder="$t('createTitle')"
                  :readonly="!creator"
                  class="inherit"
                  data-cy="title"
                />
              </h1>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-col class="pa-0">
                  <h5>{{ $t("createType") }}</h5>
                  <v-select
                    v-model="newItem.artworkType"
                    :items="typeText"
                    :readonly="!creator"
                    item-text="translation"
                    item-value="type"
                    dense
                    outlined
                    :placeholder="$t('createOptional')"
                    data-cy="artworkType"
                  ></v-select>
                </v-col>
                <v-col class="pa-0">
                  <div style="width: 100%">
                    <h5>{{ $t("createClass") }}</h5>
                    <v-select
                      v-model="newItem.artworkClass"
                      :items="classText"
                      item-text="translation"
                      item-value="class"
                      dense
                      outlined
                      :disabled="newItem.artworkType === null"
                      :placeholder="$t('createOptional')"
                      data-cy="artworkClass"
                    ></v-select>
                  </div>
                </v-col>

                <v-col class="pa-0">
                  <div style="width: 100%" id="hashtagBox">
                    <h5>{{ $t("createTag") }}</h5>
                    <v-combobox
                      v-model="newItem.hashtag"
                      @change="removeInvalidHashtag"
                      :delimiters="[' ']"
                      multiple
                      chips
                      dense
                      outlined
                      deletable-chips
                      class="chips"
                      style="max-height: none"
                      data-cy="hashtag"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          :key="JSON.stringify(data.item)"
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          :disabled="data.disabled"
                          @click:close="data.parent.selectItem(data.item)"
                          color="secondary"
                          class="px-1 py-3"
                          close
                          close-icon="mdi-close"
                          label
                          small
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>
                      <template v-slot:label>
                        <p
                          class="pa-0"
                          style="text-transform: none; letter-spacing: 0px; color: #b2b2b2; font-weight: 400"
                        >
                          {{ $t("createOptional") }}
                        </p>
                      </template>
                    </v-combobox>
                  </div>
                </v-col>
                <v-col class="pa-0">
                  <event-code-input
                    v-model="newItem.eventCodes"
                    :eventNames="editedItem.eventNames"
                    :editable="creator"
                    :key="`eventcode-${editedItem.workid ? editedItem.workid : ''}`"
                  />
                </v-col>
              </v-col>
              <v-col cols="12" sm="6">
                <v-col class="pa-0">
                  <h5>{{ $t("createDescription") }}</h5>
                  <v-textarea
                    v-model="newItem.description"
                    outlined
                    :placeholder="$t('createOptional')"
                    data-cy="description"
                    hide-details
                  ></v-textarea>
                </v-col>
                <v-col class="pa-0" v-if="notNewItem">
                  <div :key="item.fileid" v-for="item in editedItem.workFiles" class="fileDisplay">
                    <div id="fileName">
                      <v-icon v-if="item.fileType === '.pdf'">mdi-file-pdf-box</v-icon>
                      <v-icon v-else-if="item.fileType === '.png'">mdi-file-png-box</v-icon>
                      <v-icon v-else-if="item.fileType === '.ppt' || item.fileType === '.pptx'">
                        mdi-file-powerpoint
                      </v-icon>
                      <v-icon
                        v-else-if="
                          item.fileType === '.mp4' ||
                          item.fileType === '.mov' ||
                          item.fileType === 'webm' ||
                          item.fileType === 'mkv' ||
                          item.fileType === 'mkv' ||
                          item.fileType === 'mpg' ||
                          item.fileType === 'mpeg' ||
                          item.fileType === 'avi'
                        "
                        >mdi-file-video-outline</v-icon
                      >
                      <v-icon v-else>mdi-file-outline</v-icon>
                      <p class="mx-3">{{ item.fileName }}</p>
                    </div>
                  </div>
                </v-col>
                <!-- <v-col class="pa-0">
                  <seller-preference-edit :sellerPreference="newItem.sellerPreference" />
                </v-col> -->
                <v-col class="pa-0" v-if="!notNewItem">
                  <div>
                    <div class="ma-0 fileList" v-for="(item, index) in editedItem.workFiles" :key="index">
                      <p class="ma-0">{{ item.fileDescriptor.fileName }}</p>
                      <v-btn x-small text class="ma-0 pa-0">
                        <img
                          src="@/assets/zeplin/close-1.svg"
                          alt=""
                          height="17px"
                          width="17px"
                          @click="deleteFile(index)"
                          data-cy="deleteCoverPhoto"
                      /></v-btn>
                    </div>
                  </div>
                  <h5>{{ $t("createFileLabel") }}</h5>
                  <div>
                    <div class="fileDisplay" v-for="(item, index) in this.newItem.files" :key="index">
                      <div id="fileName" class="mx-2 my-0">
                        <img src="@/assets/zeplin/correct-1.svg" class="correct-1" />

                        <p class="mx-3">{{ item.name }}</p>
                      </div>
                      <div id="cancel">
                        <v-btn text color="#b92d32" small @click="removeFile(index)" :data-cy="`removeFile-${index}`"
                          >Cancel</v-btn
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="newFileUploader"
                    v-if="remainingStorage > 0"
                    :style="{ 'background-color': fileUploaderDragOver ? '#e7eaee' : '' }"
                    @drop="processFile($event.dataTransfer.files)"
                    @dragover="fileUploaderDragOver = true"
                    @dragleave="fileUploaderDragOver = false"
                    @dragover.prevent
                    @dragleave.prevent
                    @drop.prevent
                  >
                    <p v-if="!loading">
                      {{ $t("createFileUploaderHint") }}
                    </p>
                    <v-progress-circular v-if="loading" indeterminate size="15" width="2"></v-progress-circular>
                    <div class="button-wrap">
                      <label class="button" for="upload">{{ $t("createFileUploaderLabel") }}</label>
                      <input
                        id="upload"
                        type="file"
                        @change="processFile($event.target.files)"
                        multiple
                        data-cy="fileInput"
                      />
                    </div>
                  </div>
                  <div class="newFileUploader" v-if="remainingStorage <= 0" @click="$router.push('/platform/premium')">
                    <div class="button-wrap">
                      <p style="font-size: 12px; color: #b2b2b2">
                        {{ this.$t("dialogMsg.createArtwork.upgrade") }}
                      </p>
                    </div>
                  </div>
                  <div class="fileUploadHint">
                    <p>{{ $t("createFile") }}</p>
                  </div>
                </v-col>

                <v-col class="pa-0">
                  <div class="warningMessage my-2" v-if="totalFileSize > 1000000000">
                    <v-icon class="mr-3"> mdi-alert </v-icon>
                    {{ this.$t("dialogMsg.createArtwork.largeSize") }}
                  </div>
                </v-col>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  rounded
                  x-large
                  depressed
                  width="150px"
                  @click="showDialog"
                  :disabled="!this.notNewItem && remainingStorage < 0"
                  data-cy="createSave"
                  >{{ this.$t("createSave") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card
        class="addArtwork"
        :style="{ padding: $screen.width > 600 ? '32px' : '16px' }"
        style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 60vh"
        v-if="workUploadState.loading"
      >
        <random-loading-indicator
          :item="workUploadState"
          style="flex: 1; justify-content: center; flex-direction: column"
        />
        <v-stepper flat style="width: 100%" v-if="workUploadState.showStepper">
          <v-stepper-header>
            <v-stepper-step :complete="workUploadState.stepperStage > 0" step="1">
              {{ $t("createStepperCoverPhoto") }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="workUploadState.stepperStage > 1" step="2" v-if="numberOfAdditionalFiles > 0">
              {{ $t("createStepperAdditionalFiles") }}
            </v-stepper-step>
            <v-divider v-if="numberOfAdditionalFiles > 0"></v-divider>
            <v-stepper-step
              :step="numberOfAdditionalFiles > 0 ? '3' : '2'"
              :complete="
                numberOfAdditionalFiles > 0 ? workUploadState.stepperStage > 2 : workUploadState.stepperStage > 1
              "
            >
              {{ $t("createStepperUploadWork") }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :step="numberOfAdditionalFiles > 0 ? '4' : '3'"
              :complete="
                numberOfAdditionalFiles > 0 ? workUploadState.stepperStage > 3 : workUploadState.stepperStage > 2
              "
            >
              {{ $t("createStepperTimestamp") }}
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-card>
      <v-card
        class="addArtwork"
        :style="{ padding: $screen.width > 600 ? '32px' : '16px' }"
        style="display: flex; justify-content: center; align-items: center; height: 60vh"
        v-if="loading"
      >
        <v-progress-circular size="60" indeterminate color="primary"></v-progress-circular>
      </v-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ImageUploader from "../../components/Platform/ImageUploader.vue";
import copyrightCategory from "@/data/copyrightCategory.js";
import config from "@/config";
import { uploadCoverPhotoToBFS, uploadPrivateFileToBFS } from "@/services/fileService";
import { uploadWorks, uploadWorkInfo } from "@/services/workService";
import PlatformLayout from "@/layouts/Platform";
import MessageBox from "../../components/Common/MessageBox.vue";
import ConfirmDlg from "../../components/Platform/ConfirmDlg.vue";
import EventCodeInput from "../../components/Common/eventCodeInput.vue";
import { displayWorkByWorkid, getWorkEventCodes, uploadUnmanagedWorkInfo } from "../../services/workService";
import SellerPreferenceEdit from "../../components/Platform/SellerPreferenceEdit.vue";
import RandomLoadingIndicator from "../../components/Common/RandomLoadingIndicator.vue";

export default {
  components: {
    ImageUploader,
    ConfirmDlg,
    ImageFromFileDescriptor: () => import("@/components/Common/ImageFromFileDescriptor.vue"),
    MessageBox,
    EventCodeInput,
    SellerPreferenceEdit,
    RandomLoadingIndicator
  },
  data: () => ({
    saving: false,
    copyrightCategory: copyrightCategory,
    messageBox: {
      state: false,
      title: "",
      content: "",
      cssClass: "error",
      destination: ""
    },
    coverPhotoIndex: 0,
    newItem: {
      coverPhotos: [],
      title: "",
      artworkType: null,
      artworkClass: null,
      hashtag: [],
      description: null,
      files: [],
      additionalFiles: [],
      eventCodes: [],
      keyPhoto: 0,
      sellerPreference: {
        orderType: "negotiable",
        price: null,
        duration: null
      }
    },
    editedItem: { coverPhotos: [] },
    remainingStorage: 1000,
    fileUploaderDragOver: false,
    loading: false,
    viewImageIndex: 0,
    modalWindow: false,
    imageEditDialogBox: false,
    imageEditIndex: 0,
    dragOver: false,
    overrideLeaveSave: true,
    showPrivateLimitWarning: false,
    workUploadState: {
      loading: false,
      message: "",
      subtitle: "",
      stepperStage: null,
      showStepper: true
    }
  }),
  name: "AddArtwork",
  async mounted() {
    this.$emit(`update:layout`, PlatformLayout);
    this.calculateRemainingStorage();

    this.$store.commit("setBackDestination", {
      show: true,
      path: "/platform/mycollection",
      name: this.$t("titleMyCollection")
    });

    if (this.notNewItem) {
      this.loading = true;
      const workid = this.$route.params.workid;
      const result = await displayWorkByWorkid(this.$axios, config, workid, this.$session.get("token"));

      if (result.status === 200) {
        this.editedItem = result.data;
      } else {
        this.overrideLeaveSave = true;
        this.$router.push("/platform/mycollection");
      }
      this.loading = false;
    }
    const cleanKeyPhotoIndex = (i) => {
      if (!i) {
        return 0;
      }
      if (i < 0) {
        return 0;
      }
      return i;
    };
    if (this.notNewItem) {
      this.newItem.title = this.editedItem.workInfo.artworkTitle;
      this.newItem.artworkType = this.editedItem.workInfo.artworkType;

      this.newItem.artworkClass = this.editedItem.unmanagedInfo.artworkClass;
      this.newItem.hashtag = this.editedItem.unmanagedInfo.hashTags;
      this.newItem.description = this.editedItem.unmanagedInfo.description;
      this.viewImageIndex = cleanKeyPhotoIndex(this.editedItem.unmanagedInfo.keyPhoto);
      if (Object.hasOwnProperty.call(this.editedItem.unmanagedInfo, "sellerPreference")) {
        this.newItem.sellerPreference = this.editedItem.unmanagedInfo.sellerPreference;
      }
      const token = this.$session.get("token");
      let eventCodes = await getWorkEventCodes(this.$axios, config, this.editedItem.workid, token);
      if (eventCodes.data) {
        this.newItem.eventCodes = eventCodes.data.map((elem) => {
          return elem.eventCode;
        });
      }
    }
    this.overrideLeaveSave = true;
  },
  methods: {
    removeImage() {
      this.newItem.image = null;
      this.calculateRemainingStorage();
    },
    close() {
      this.$router.push("/platform/mycollection");
    },
    processFile(files) {
      this.fileUploaderDragOver = false;
      files.forEach((file) => {
        this.newItem.files.push(file);
      });

      this.calculateRemainingStorage();
    },
    async removeInvalidHashtag() {
      if (this.newItem.hashtag.length >= 5 && !this.isPremium) {
        if (!this.$refs.confirm) {
          return;
        }
        if (
          await this.$refs.confirm.open(
            this.$t("dialogMsg.createArtwork.exceedHashtag.title"),
            this.$t("dialogMsg.createArtwork.exceedHashtag.subtitle")
          )
        ) {
          this.$router.push("/platform/premium").catch((e) => e);
        }
        this.newItem.hashtag = this.newItem.hashtag.slice(0, 4);
      }
      this.newItem.hashtag = this.newItem.hashtag.filter((str) => {
        return str.replace(/\s/g, "").length;
      });
    },
    async uploadFile(elem) {
      const token = this.$session.get("token");
      try {
        const formDataFile = new FormData();
        formDataFile.append("file", elem);
        const result = await uploadPrivateFileToBFS(this.$axios, config, formDataFile, token);

        if (result.status === 200) {
          this.newItem.additionalFiles.push(result.data.fileDescriptor);
          return true;
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
          this.loading = false;
          return false;
        }
      } catch (error) {
        this.messageBox.content = `The additional files cannot be uploaded.`;
        this.messageBox.state = true;
        this.messageBox.cssClass = "error";
        this.messageBox.destination = "";
        this.loading = false;
      }
    },
    async uploadAdditionalFiles() {
      let promiseArray = this.newItem.files.map((elem) => {
        return this.uploadFile(elem);
      });

      let noError;
      await Promise.allSettled(promiseArray).then((res) => {
        noError = res.every((elem) => {
          return elem.value === true;
        });

        if (!noError) {
          this.newItem.files = [];
        }
      });

      return noError;
    },
    setKeyPhoto(index) {
      const cleanKeyPhotoIndex = (i) => {
        if (!i) {
          return 0;
        }
        if (i < 0) {
          return 0;
        }
        return i;
      };
      if (!this.notNewItem) {
        this.newItem.keyPhoto = cleanKeyPhotoIndex(index);
      } else {
        this.editedItem.unmanagedInfo.keyPhoto = cleanKeyPhotoIndex(index);
      }
    },
    removeCoverPhoto(index) {
      if (this.newItem.keyPhoto > index) {
        this.newItem.keyPhoto = this.newItem.keyPhoto - 1;
      }
      this.newItem.coverPhotos.splice(index, 1);
      this.calculateRemainingStorage();
    },
    removeFile(index) {
      this.newItem.files.splice(index, 1);
      this.calculateRemainingStorage();
    },
    async uploadWork() {
      if (this.remainingStorage < 0) {
        if (this.isPremium) {
          if (!this.$refs.confirm) {
            return;
          }
          if (await this.$refs.confirm.open(this.$t("dialogMsg.createArtwork.storage"), "")) {
            this.overrideLeaveSave = true;
            this.$router.push("/platform/premium").catch((e) => e);
          }
        } else {
          if (await this.$refs.confirm.open(this.$t("dialogMsg.createArtwork.upgrade"), "")) {
            this.overrideLeaveSave = true;
            this.$router.push("/platform/premium").catch((e) => e);
          }
        }
        return;
      }

      if (this.disableHashtag) {
        if (!this.$refs.confirm) {
          return;
        }
        if (await this.$refs.confirm.open(this.$t("dialogMsg.createArtwork.exceedHashtag"), "")) {
          this.overrideLeaveSave = true;
          this.$router.push("/platform/premium").catch((e) => e);
        }
        return;
      }

      const token = this.$session.get("token");
      const keypair = this.$session.get("keypair");

      if (!this.notNewItem) {
        let statusCode;
        this.workUploadState = {
          loading: true,
          message: this.$t(
            this.newItem.coverPhotos.length > 1 ? "createLoadingCoverPhotos" : "createLoadingCoverPhoto",
            {
              numberOfCoverPhotos: this.newItem.coverPhotos.length,
              fileSize: this.calculateArrayFileSize(this.newItem.coverPhotos)
            }
          ),
          subtitle: this.$t("createLoadingDoNotCloseUpload"),
          stepperStage: 0,
          showStepper: true
        };
        const coverPhotosPromise = this.newItem.coverPhotos.map(async (image) => {
          const formData = image.formData;

          const result = await uploadCoverPhotoToBFS(this.$axios, config, formData, this.$session.get("token"));

          if (result.status === 200) {
            return result.data.fileDescriptor;
          } else {
            statusCode = result.status;
            this.messageBox.content = this.$t(result.data.message);
            this.messageBox.cssClass = "error";
            this.messageBox.state = true;
            this.messageBox.destination = "";
            this.loading = false;
            return false;
          }
        });
        if (this.numberOfAdditionalFiles > 0) {
          this.workUploadState = {
            loading: true,
            message: this.$t(
              this.newItem.files.length > 1 ? "createLoadingAdditionalFiles" : "createLoadingAdditionalFile",
              {
                numberOfAdditionalFiles: this.newItem.files.length,
                fileSize: this.calculateArrayFileSize(this.newItem.files)
              }
            ),
            subtitle: this.$t("createLoadingDoNotCloseUpload"),
            stepperStage: 1,
            showStepper: true
          };
        }
        // Upload image to BFS and retrieve file descriptor
        // const coverPhoto = await uploadCoverPhotoToBFS(this.$axios, config, this.newItem.image.formData, token);
        const additionalFileNoError = this.uploadAdditionalFiles();

        const coverPhotosArray = await Promise.all(coverPhotosPromise).then((values) => {
          return values;
        });

        this.checkCoverPhotoArray(coverPhotosArray, statusCode);

        await additionalFileNoError;
        if (!additionalFileNoError) {
          this.messageBox.content = `The additional files cannot be uploaded.`;
          this.messageBox.state = true;
          this.messageBox.cssClass = "error";
          this.messageBox.destination = "";
          return;
        }

        this.workUploadState = {
          loading: true,
          message: this.$t("createLoadingUploadWorks"),
          subtitle: this.$t("createLoadingDoNotClose"),
          showStepper: true
        };

        const workInfo = {
          artworkTitle: this.newItem.title,
          artworkType: this.newItem.artworkType
        };

        const unmanagedInfo = {
          artworkClass: this.newItem.artworkClass,
          hashTags: this.newItem.hashtag,
          description: this.newItem.description,
          sellerPreference: this.newItem.sellerPreference,
          keyPhoto: this.newItem.keyPhoto
        };
        const payload = {
          work: {
            workType: 0,
            workInfo: JSON.stringify(workInfo),
            unmanagedInfo: JSON.stringify(unmanagedInfo),
            coverPhotos: coverPhotosArray,
            workFiles: this.newItem.additionalFiles
          },
          user: keypair
        };

        payload.work.eventCodes = this.newItem.eventCodes;
        this.workUploadState = {
          loading: true,
          message: this.$t("createStepperTimestamp"),
          subtitle: this.$t("createLoadingDoNotClose"),
          stepperStage: this.numberOfAdditionalFiles > 0 ? 3 : 2,
          showStepper: true
        };
        const delay = (ms) => new Promise((res) => setTimeout(res, ms));
        await delay(2000);

        const result = await uploadWorks(this.$axios, config, token, payload);

        if (result.status === 200) {
          let newItem = result.data;
          newItem.ownerAddress = newItem.creatorAddress;
          let workList = [newItem].concat(this.$store.getters.getWorkList);
          this.$store.commit("updateWorkList", workList);
          this.$store.dispatch("updateArtwork", { axios: this.$axios, config, token, workid: newItem.workid });
          this.overrideLeaveSave = true;
          this.$router.push(`/platform/mycollection/${newItem.workid}`);
          localStorage.setItem("promptWorkPublish", newItem.workid);
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        }
      } else {
        this.workUploadState = {
          loading: true,
          message: this.$t("createLoadingUpdatingWork"),
          subtitle: this.$t("createLoadingDoNotCloseUpload"),
          showStepper: false
        };
        let payload;
        const address = this.$store.state.userProfile.address;
        const workid = this.editedItem.workid;
        let unmanagedWorkInfo = this.editedItem.unmanagedInfo;
        if (unmanagedWorkInfo === "") {
          unmanagedWorkInfo = {};
        }
        unmanagedWorkInfo.artworkClass = this.newItem.artworkClass;
        unmanagedWorkInfo.hashTags = this.newItem.hashtag;
        unmanagedWorkInfo.description = this.newItem.description;
        unmanagedWorkInfo.sellerPreference = this.newItem.sellerPreference;
        unmanagedWorkInfo.keyPhoto = this.viewImageIndex;
        let result;
        if (address === this.editedItem.creatorAddress) {
          const workInfo = this.editedItem.workInfo;
          workInfo.artworkTitle = this.newItem.title;
          workInfo.artworkType = this.newItem.artworkType;

          payload = {
            workInfo: JSON.stringify(workInfo),
            unmanagedInfo: JSON.stringify(unmanagedWorkInfo),
            eventCodes: this.newItem.eventCodes,
            privateKey: keypair.privateKey,
            publicKey: keypair.publicKey
          };
          result = await uploadWorkInfo(this.$axios, config, token, workid, payload);
        } else {
          payload = {
            unmanagedInfo: JSON.stringify(unmanagedWorkInfo),
            eventCodes: this.newItem.eventCodes,
            privateKey: keypair.privateKey,
            publicKey: keypair.publicKey
          };
          result = await uploadUnmanagedWorkInfo(this.$axios, config, token, workid, payload);
        }

        if (result.status !== 200) {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";

          return;
        } else {
          const displayNewItem = result.data;
          displayNewItem.ownerAddress = this.editedItem.ownerAddress;
          displayNewItem.workInfo = JSON.parse(displayNewItem.workInfo);
          displayNewItem.unmanagedInfo = JSON.parse(displayNewItem.unmanagedInfo);

          let workList = this.$store.getters.getWorkList.map((elem) => {
            if (elem.workid === this.workid) {
              return displayNewItem;
            } else {
              return elem;
            }
          });
          this.$store.commit("updateWorkList", workList);
          this.$store.dispatch("updateArtwork", {
            axios: this.$axios,
            config,
            token,
            workid
          });
          this.overrideLeaveSave = true;
          this.$router.push(`/platform/mycollection/${this.workid}`);
        }
      }
    },
    changeEventCode(event) {
      this.newItem.eventCodes = event;
    },
    async showDialog() {
      if (!this.notNewItem && this.newItem.coverPhotos.length === 0) {
        this.messageBox.content = this.$t("dialogMsg.createArtwork.coverphoto");
        this.messageBox.state = true;
        this.messageBox.cssClass = "error";
        this.messageBox.destination = "";
        return;
      }

      if (this.newItem.title.trim() === "") {
        this.messageBox.content = this.$t("dialogMsg.createArtwork.title");
        this.messageBox.state = true;
        this.messageBox.cssClass = "error";
        this.messageBox.destination = "";
        return;
      }
      let messageContent;
      if (!this.notNewItem) {
        if (this.showPrivateLimitWarning) {
          messageContent = this.$t("dialogMsg.myCollection.beforePrivateLimit");
        } else {
          messageContent = this.$t("dialogMsg.createArtwork.confirm");
        }
        if (!this.$refs.confirm) {
          return;
        }
        let proceed = await this.$refs.confirm.open(messageContent, "");
        if (proceed) {
          this.uploadWork();
        }
      } else {
        this.uploadWork();
      }
    },
    deleteFile(index) {
      this.newItem.additionalFiles.splice(index, 1);
    },
    async calculateRemainingStorage() {
      const allFiles = this.newItem.coverPhotos.concat(this.newItem.files);
      let totalFileSize = allFiles.reduce((total, b) => {
        return total + b.size;
      }, 0);
      await this.$store.dispatch("loadUserProfile", {
        axios: this.$axios,
        config: config,
        token: this.$session.get("token")
      });
      let quota = this.$store.state.userProfile.storageQuota;
      this.remainingStorage = quota - totalFileSize / 1024;
      if (this.remainingStorage < 0) {
        if (this.isPremium) {
          if (!this.$refs.confirm) {
            return;
          }
          if (await this.$refs.confirm.open(this.$t("dialogMsg.createArtwork.storage"), "")) {
            this.overrideLeaveSave = true;
            this.$router.push("/platform/premium").catch((e) => e);
          }
        } else {
          if (!this.$refs.confirm) {
            return;
          }
          if (await this.$refs.confirm.open(this.$t("dialogMsg.createArtwork.upgrade"), "")) {
            this.overrideLeaveSave = true;
            this.$router.push("/platform/premium").catch((e) => e);
          }
        }
      }
    },
    calculateArrayFileSize(fileArray) {
      let totalFileSize = fileArray.reduce((total, b) => {
        return total + b.size;
      }, 0);
      return Math.round(10 * totalFileSize * 0.000001) / 10;
    },
    async checkCoverPhotoArray(coverPhotosArray, statusCode) {
      if (!coverPhotosArray.every((e) => e)) {
        this.workUploadState = {
          loading: false,
          message: ""
        };
        if (statusCode === 403) {
          if (this.isPremium) {
            if (await this.$refs.confirm.open(this.$t("dialogMsg.createArtwork.storage"), "")) {
              this.overrideLeaveSave = true;
              this.$router.push("/platform/premium").catch((e) => e);
            }
          } else {
            if (await this.$refs.confirm.open(this.$t("dialogMsg.createArtwork.upgrade"), "")) {
              this.overrideLeaveSave = true;
              this.$router.push("/platform/premium").catch((e) => e);
            }
          }
        }
        return;
      }
    },
    imageEditDialog(index) {
      this.imageEditDialogBox = true;
      this.imageEditIndex = index;
    },
    async exceedCoverPhotoLimit() {
      this.calculateRemainingStorage();
      if (this.remainingStorage < 0) {
        return;
      }
      if (!this.$refs.confirm) {
        return;
      }
      if (await this.$refs.confirm.open(this.$t("dialogMsg.createArtwork.upgradeCoverPhoto"), "")) {
        this.overrideLeaveSave = true;
        this.$router.push("/platform/premium").catch((e) => e);
      }
    },
    dragOverAdditionalCoverPhoto(event) {
      this.$refs.imageUploader.drop(event);
      this.dragOver = false;
    },
    async checkPrivateLimit() {
      this.loading = true;
      const numberOfPrivateWorks = await this.$store.dispatch("getPrivateLimit", {
        axios: this.$axios,
        config: config,
        token: this.$session.get("token")
      });
      this.loading = false;

      if (numberOfPrivateWorks >= config.privateWorksLimit) {
        if (await this.$refs.confirm.open(this.$t("dialogMsg.myCollection.privateLimit"), "")) {
          this.overrideLeaveSave = true;
          this.$router.push("/platform/premium").catch((e) => e);
        } else {
          this.overrideLeaveSave = true;
          this.$router.push("/platform/mycollection").catch((e) => e);
        }
      } else if (numberOfPrivateWorks === config.privateWorksLimit - 1) {
        this.showPrivateLimitWarning = true;
      }
    }
  },
  computed: {
    typeText() {
      let artworkType = [
        { translation: this.$t("artworkTypeName0"), type: 0 },
        { translation: this.$t("artworkTypeName1"), type: 1 },
        { translation: this.$t("artworkTypeName2"), type: 2 },
        { translation: this.$t("artworkTypeName3"), type: 3 },
        { translation: this.$t("artworkTypeName4"), type: 4 }
      ];
      return artworkType;
    },
    classText() {
      if (this.newItem.artworkType || this.newItem.artworkType === 0) {
        let artworkClass = "artworkType".concat(this.newItem.artworkType);
        let artworkClassObj = this.$t(artworkClass);
        let artworkClassArray = Object.keys(artworkClassObj).map((key) => {
          return {
            translation: artworkClassObj[key],
            class: parseInt(key.substring(5, 8))
          };
        });
        return artworkClassArray;
      } else {
        return [
          {
            translation: "",
            class: null
          }
        ];
      }
    },
    notNewItem() {
      return !!this.$route.params.workid;
    },

    creator() {
      return this.notNewItem ? this.$store.state.userProfile.address === this.editedItem.creatorAddress : true;
    },
    isPremium() {
      return this.$store.state.userProfile.isPremium;
    },
    coverPhotoLimit() {
      if (this.isPremium) {
        return 1000;
      } else {
        return 3;
      }
    },
    disableHashtag() {
      return this.isPremium ? false : this.newItem.hashtag.length > 5;
    },
    workid() {
      return this.$route.params.workid;
    },
    totalFileSize() {
      const allFiles = this.newItem.coverPhotos.concat(this.newItem.files);
      let totalFileSize = allFiles.reduce((total, b) => {
        return total + b.size;
      }, 0);
      return totalFileSize;
    },
    numberOfAdditionalFiles() {
      return this.newItem.files.length;
    }
  },
  watch: {
    "newItem.coverPhotos": function () {
      this.calculateRemainingStorage();
    },
    newItem: {
      handler: function () {
        this.overrideLeaveSave = false;
      },
      deep: true
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.overrideLeaveSave) {
      next();
    } else {
      if (!this.$refs.confirm) {
        return;
      }
      const answer = await this.$refs.confirm.open(
        this.$t("dialogMsg.createArtwork.leave.title"),
        this.$t("dialogMsg.createArtwork.leave.subtitle")
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
  }
};
</script>
