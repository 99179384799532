<template>
  <h5 style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 200px">
    <span v-if="eventName">{{ this.eventName }}</span>
    <v-skeleton-loader type="text" v-else width="100px"></v-skeleton-loader>
  </h5>
</template>

<script>
import config from "../../config";
import { checkEvent } from "../../services/workService";
export default {
  props: {
    eventCode: {
      type: String
    }
  },
  data: () => ({
    eventName: null
  }),
  async created() {
    const result = await checkEvent(this.$axios, config, this.eventCode.trim());
    this.eventName = result.data.eventName;
  }
};
</script>
