<template>
  <div>
    <MessageBox v-model="messageBox.state" :message-content="messageBox.content" :message-class="messageBox.cssClass" />

    <div v-if="!dialog" key="myCollection">
      <div id="displayCard" :style="{ 'margin-bottom': $screen.width > 600 ? '100px' : null }">
        <confirm-dlg ref="confirm" />

        <v-card v-if="!loading" class="myCollectionCard">
          <h1>{{ this.$t("collectionHeading") }}</h1>

          <div class="searchToolbar">
            <v-text-field
              filled
              rounded
              single-line
              hide-details
              full-width
              :placeholder="$t('bazaarSelect')"
              v-model="searchInput"
              style="border-radius: 30px; width: 800px"
            >
              <template v-slot:prepend-inner>
                <v-icon color="primary">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              rounded
              depressed
              color="primary"
              height="56px"
              width="150px"
              @click="newItem"
              v-if="$screen.width > 600"
              style="margin-left: 5vw"
            >
              <span style="font-weight: 600">{{ this.$t("collectionCreate") }}</span>
            </v-btn>
            <v-btn color="primary" fab size="50px" @click="newItem" class="floatingButton" v-else
              ><v-icon x-large>mdi-file-upload-outline</v-icon>
            </v-btn>
          </div>

          <status-code-bar
            :code="
              statusCode.filter((e) => {
                return !e.hide;
              })
            "
            v-if="hasWorks"
          ></status-code-bar>

          <div id="scrollMenu" v-if="hasWorks">
            <vue-scroll>
              <v-data-table
                :headers="headers.filter((header) => header.inTable)"
                :items="filteredWorks"
                sort-by="-createdAt"
                hide-default-footer
                :items-per-page="itemsPerPage"
                :page.sync="pageNumber"
                @page-count="pageCount = $event"
                v-if="hasWorks && !this.$store.state.showProgressLine && $screen.width > 600"
              >
                <template v-slot:item.createdAt="{ item }">
                  {{ formatDate(item.createdAt) }}
                </template>
                <template v-slot:item.creatorAddress="{ item }">
                  <user-id-from-address :address="item.creatorAddress" :key="item.workid.concat('_creator')" />
                </template>
                <template v-slot:item.ownerAddress="{ item }">
                  <v-tooltip bottom v-if="item.licensingCount > 0 && !isLicensee(item)">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <user-id-from-address :address="item.ownerAddress" :key="item.workid.concat('_owner')" /></span
                    ></template>
                    <div v-if="item.licensingCount > 0">
                      <b>Licensees:</b>
                      <div v-for="(licensee, l) in item.licensees" :key="l">
                        <user-id-from-address :address="licensee" />
                      </div></div
                  ></v-tooltip>
                  <user-id-from-address :address="item.ownerAddress" :key="item.workid.concat('_owner')" v-else />
                </template>
                <template v-slot:item.artworkTitle="{ item }">
                  <i
                    style="color: #9e9e9e"
                    v-if="!item.workInfo.artworkTitle || item.workInfo.artworkTitle.length === 0"
                  >
                    {{ $t("artworkUntitled") }}
                  </i>
                  <span v-else>{{ item.workInfo.artworkTitle }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div style="display: flex; flex-wrap: wrap" :key="item.workid.concat('actions')">
                    <div key="delete">
                      <v-tooltip bottom v-if="allowDelete(item)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="deleteItemDialog(item)" v-if="item.approvalStatus !== 'licensed'">
                            <img
                              src="@/assets/icon_pack/40px/Cancel.svg"
                              alt=""
                              height="20px"
                              width="20px"
                              v-bind="attrs"
                              v-on="on"
                            />
                          </v-btn>
                        </template>
                        <span>{{ $t("collectionDelete") }}</span>
                      </v-tooltip>
                    </div>
                    <div key="allowEdit">
                      <v-tooltip bottom v-if="allowEdit(item)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="editItem(item)" icon>
                            <img
                              src="@/assets/zeplin/writer.svg"
                              alt=""
                              height="20px"
                              width="20px"
                              v-bind="attrs"
                              v-on="on"
                            />
                          </v-btn>
                        </template>
                        <span>{{ $t("collectionEdit") }}</span>
                      </v-tooltip>
                    </div>
                    <div key="revert">
                      <v-tooltip bottom v-if="allowRevertToPrivate(item)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="revertToPrivate(item)" icon v-bind="attrs" v-on="on">
                            <v-icon size="20">mdi-lock-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("collectionPrivate") }}</span>
                      </v-tooltip>
                    </div>
                    <div key="publish">
                      <v-tooltip bottom v-if="allowPublish(item)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="publish(item)" icon v-bind="attrs" v-on="on">
                            <img src="@/assets/icon_pack/40px/icn_publish_v1.1.svg" alt="" height="20px" width="20px" />
                          </v-btn>
                        </template>
                        <span>{{ $t("viewPublish") }}</span>
                      </v-tooltip>
                    </div>

                    <v-tooltip bottom v-if="blockchainUpdateWait(item.workid) < 100">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn :ripple="false" icon v-bind="attrs" v-on="on">
                          <v-progress-circular
                            :value="blockchainUpdateWait(item.workid)"
                            size="17"
                            width="3"
                            color="primary"
                          />
                        </v-btn>
                      </template>
                      <span>{{ $t("collectionUpdate") }}</span>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:item.view="{ item }">
                  <div v-if="allowView(item)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="viewItem(item)" icon v-bind="attrs" v-on="on">
                          <img src="@/assets/zeplin/view-icon.svg" alt="" height="17px" width="17px" />
                        </v-btn>
                      </template>
                      {{ $t("collectionBazaar") }}
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:item.approvalStatus="{ item }">
                  <span :style="getStatusColor(item)">
                    {{ getStatusCode(item).longLabel }}
                  </span>
                </template>
              </v-data-table>
              <div v-if="$screen.width <= 600">
                <div
                  v-for="(item, i) in works.slice(
                    this.itemsPerPage * (this.pageNumber - 1),
                    this.itemsPerPage * this.pageNumber
                  )"
                  :key="i.toString().concat('_mobile')"
                >
                  <v-col class="mb-2 pa-0" style="background-color: #f6f8f9">
                    <div class="headline d-flex flex-row-reverse" style="background-color: #dddddd">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            class="pa-0 px-2"
                            text
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            :disabled="blockchainUpdateWait(item.workid) < 100 || isLicensee(item)"
                            ><h5 v-if="blockchainUpdateWait(item.workid) >= 100">{{ headers[0].text }}</h5>

                            <v-progress-circular
                              :value="blockchainUpdateWait(item.workid)"
                              v-if="blockchainUpdateWait(item.workid) < 100"
                              size="17"
                              width="3"
                              color="primary"
                              class="ml-2"
                            />
                            <v-icon v-else>mdi-menu-down</v-icon>
                          </v-btn>
                        </template>
                        <div class="speech-bubble">
                          <div class="d-flex flex-row align-center" v-if="allowDelete(item)">
                            <v-btn depressed @click="deleteItemDialog(item)" text class="pa-0">
                              <img src="@/assets/icon_pack/40px/Cancel.svg" alt="" height="20px" width="20px" />
                              <p style="color: #be3b40">{{ $t("collectionDelete") }}</p>
                            </v-btn>
                          </div>
                          <div class="d-flex flex-row align-center" v-if="allowEdit(item)">
                            <v-btn @click="editItem(item)" text class="pa-0">
                              <img src="@/assets/zeplin/writer.svg" alt="" height="20px" width="20px" />
                              <p style="color: #63a3c3">{{ $t("collectionEdit") }}</p>
                            </v-btn>
                          </div>
                          <div class="d-flex flex-row align-center" v-if="allowRevertToPrivate(item)">
                            <v-btn @click="revertToPrivate(item)" text color="primary" class="pa-0">
                              <v-icon height="20px" width="20px">mdi-lock-outline</v-icon>
                              <p style="color: #63a3c3">{{ $t("collectionPrivate") }}</p>
                            </v-btn>
                          </div>
                          <div class="d-flex flex-row align-center" v-if="allowView(item)">
                            <v-btn @click="viewItem(item)" text class="pa-0">
                              <img src="@/assets/zeplin/view-icon.svg" alt="" height="17px" width="17px" />
                              <p style="color: #63a3c3">{{ $t("collectionBazaar") }}</p>
                            </v-btn>
                          </div>
                          <div class="d-flex flex-row align-center" v-if="allowPublish(item)">
                            <v-btn @click="publish(item)" text class="pa-0">
                              <!-- <v-icon style="color: #63a3c3">mdi-cloud-upload-outline</v-icon> -->
                              <img
                                src="@/assets/icon_pack/40px/icn_publish_v1.1.svg"
                                alt=""
                                height="17px"
                                width="17px"
                              />
                              <p style="color: #63a3c3">{{ $t("viewPublish") }}</p>
                            </v-btn>
                          </div>
                        </div>
                      </v-menu>
                    </div>
                    <v-row class="pa-2 px-6 mb-3">
                      <v-col cols="12">
                        <h5>{{ headers[2].text }}</h5>

                        <i
                          style="color: #9e9e9e"
                          v-if="!item.workInfo.artworkTitle || item.workInfo.artworkTitle.length === 0"
                        >
                          {{ $t("artworkUntitled") }}
                        </i>
                        <span v-else>{{ item.workInfo.artworkTitle }}</span>
                      </v-col>
                      <v-col cols="6">
                        <h5>{{ headers[3].text }}</h5>
                        <p>
                          <user-id-from-address :address="item.creatorAddress" :key="item.workid.concat('_creator')" />
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <h5>{{ headers[4].text }}</h5>
                        <p>
                          <user-id-from-address :address="item.ownerAddress" :key="item.workid.concat('_owner')" />
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <h5>{{ headers[5].text }}</h5>
                        <p>{{ formatDate(item.createdAt) }}</p>
                      </v-col>
                      <v-col cols="6">
                        <h5>{{ headers[1].text }}</h5>
                        <p :style="getStatusColor(item)">
                          {{ getStatusCode(item).longLabel }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </div>
              </div>
            </vue-scroll>
          </div>
          <v-alert v-if="!hasWorks && !error" style="margin-top: 32px" type="info" outlined color="primary">
            {{ $t("collectionEmpty") }}
          </v-alert>
          <v-alert v-if="error" type="error" style="margin-top: 32px" outlined> {{ this.messageBox.content }}</v-alert>
        </v-card>
        <v-card v-if="loading" min-height="80vh" class="myCollectionCard d-flex flex-column">
          <h1 style="margin-bottom: 24px">{{ this.$t("collectionHeading") }}</h1>
          <v-skeleton-loader type="table" width="100%" v-if="$screen.width >= 600"></v-skeleton-loader>
          <v-skeleton-loader type="card@3" v-else></v-skeleton-loader>
        </v-card>
      </div>
      <div class="platformFooterBg" v-if="works.length > 5"></div>
      <div class="platformFooter" v-if="works.length > 5">
        <div id="platformFooterRows">
          <p style="margin: 0">{{ $t("collectionPageNumber") }}</p>
          <v-select
            v-model="itemsPerPage"
            :items="[5, 10, 15]"
            item-value
            filled
            dense
            hide-details=""
            class="selectNoBorder"
          >
          </v-select>
        </div>

        <div id="platformFooterItem">
          <p style="margin: 0">
            {{ this.$t("collectionPage", { pageNumber, pageCount }) }}
          </p>
          <v-btn icon @click="pageNumber--" :disabled="pageNumber === 1">
            <v-icon>mdi-menu-left</v-icon>
          </v-btn>
          <v-btn icon @click="pageNumber++" :disabled="pageNumber === pageCount">
            <v-icon>mdi-menu-right</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusCodeBar from "@/components/Platform/StatusCodeBar";
import PlatformLayout from "@/layouts/Platform";
import { deleteWork } from "@/services/workService";
import { publishToBazaar } from "@/services/workService";
import config from "../../config";
import MessageBox from "@/components/Common/MessageBox";
import UserIdFromAddress from "../../components/Common/userIdFromAddress.vue";
import ConfirmDlg from "../../components/Platform/ConfirmDlg.vue";
import { retrieveLastUpdate } from "../../services/workService";

export default {
  name: "MyCollection",
  components: {
    StatusCodeBar,
    MessageBox,
    ConfirmDlg,
    UserIdFromAddress
  },
  data: () => ({
    loading: false,
    files: [],
    expand: false,
    openIdx: -1,
    dialog: false,
    viewOnly: false,
    addrList: {},
    editedIndex: -1,
    editedItem: {},
    timer: null,
    searchField: null,
    searchInput: null,
    addingArtwork: false,
    pageNumber: 1,
    itemsPerPage: 5,
    pageCount: 1,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    dialogBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: "",
      title: ""
    },
    datenow: null,
    error: false,
    toPublish: false,
    itemToPublish: {},
    sellerPreference: {
      orderType: null,
      price: null,
      duration: null
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "My Masterpiece - Upload Your Content" : "My Masterpiece - Edit Your Content";
    },
    statusCode() {
      const statusCodeArray = this.translateArray("collectionState");
      return [
        {
          label: statusCodeArray[0],
          longLabel: statusCodeArray[0],
          approvalStatus: "approved",
          workStatus: 2,
          color: "#2EB73A"
        },
        {
          label: statusCodeArray[1],
          longLabel: statusCodeArray[1],
          approvalStatus: "pending",
          workStatus: 1,
          color: "#F1CA1B"
        },
        {
          label: statusCodeArray[2],
          longLabel: statusCodeArray[2],
          approvalStatus: "licensed",
          workStatus: 2,
          color: "#F1951B"
        },
        {
          label: statusCodeArray[3],
          longLabel: statusCodeArray[3],
          approvalStatus: "private",
          workStatus: 0,
          color: "#B3A7D3"
        },
        {
          label: statusCodeArray[3],
          longLabel: statusCodeArray[3],
          approvalStatus: "private",
          workStatus: 3,
          color: "#B3A7D3",
          hide: true
        }
      ];
    },
    headers() {
      const headerArray = this.translateArray("collectionColumns");
      return [
        {
          text: headerArray[0],
          value: "actions",
          sortable: false,
          inputField: false,
          inTable: true
        },
        {
          text: headerArray[1],
          align: "left",
          sortable: true,
          value: "approvalStatus",
          inputField: false,
          inTable: true
        },
        {
          text: headerArray[2],
          align: "left",
          sortable: true,
          value: "workInfo.artworkTitle",
          inputField: true,
          inTable: true
        },
        {
          text: headerArray[3],
          align: "left",
          value: "creatorAddress",
          inputField: true,
          inTable: true
        },
        {
          text: headerArray[4],
          align: "left",
          value: "ownerAddress",
          inputField: false,
          inTable: true
        },
        {
          text: headerArray[5],
          align: "left",
          value: "createdAt",
          inputField: false,
          inTable: true
        },
        {
          text: "",
          align: "center",
          value: "view",
          sortable: false,
          inputField: false,
          inTable: true
        }
      ];
    },
    hasWorks() {
      if (this.$store.state.showProgressLine) {
        return true;
      }
      return this.works.length > 0;
    },
    works() {
      return this.$store.state.workList;
    },

    filteredWorks() {
      if (this.searchInput === "" || !this.searchInput) {
        return this.works;
      } else {
        let filter;
        return this.works.filter((elem) => {
          filter = this.searchInput.toUpperCase();
          if (elem.workid.toUpperCase().indexOf(filter) > -1) {
            return true;
          }
          if (elem.workInfo.artworkTitle) {
            if (elem.workInfo.artworkTitle.toUpperCase().indexOf(filter) > -1) {
              return true;
            }
          }

          return false;
        });
      }
    },
    sellerPreferenceValid() {
      if (this.sellerPreference.orderType === "negotiable") {
        return true;
      }

      if (this.sellerPreference.orderType === "sell" && this.sellerPreference.price) {
        return true;
      }

      if (
        this.sellerPreference.orderType === "license" &&
        this.sellerPreference.price &&
        this.sellerPreference.duration
      ) {
        return true;
      }

      if (this.sellerPreference.orderType === "notForSale") {
        return true;
      }

      return false;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  async mounted() {
    this.$emit(`update:layout`, PlatformLayout);
    this.initialize();
    this.getNow();

    if (this.$route.query.newItem) {
      this.$router.push("/platform/new").catch((e) => e);
    }
    setInterval(() => {
      this.getNow();
    }, 1000);
  },
  updated() {
    // this.role = this.$session.get('role')
  },
  methods: {
    getNow() {
      this.datenow = new Date().getTime();
    },
    getStatusCode(item) {
      if (item.licensingCount > 0) {
        let statusCode = this.statusCode.find((obj) => {
          return obj.approvalStatus === "licensed";
        });
        let address = this.$store.state.userProfile.address;

        if (item.ownerAddress === address) {
          statusCode.longLabel = this.$t("collectionStateLicenseOut");
        } else {
          statusCode.longLabel = this.$t("collectionStateLicenseIn");
        }
        return statusCode;
      }
      if (item.isHidden === true) {
        return this.statusCode.find((obj) => {
          return obj.approvalStatus === "private";
        });
      }
      return this.statusCode.find((obj) => {
        return obj.workStatus === item.workStatus;
      });
    },
    getStatusColor(item) {
      let status;
      if (item.licensingCount > 0) {
        status = this.statusCode.find((obj) => {
          return obj.approvalStatus === "licensed";
        });
      } else {
        if (item.isHidden) {
          status = this.statusCode.find((obj) => {
            return obj.approvalStatus === "private";
          });
        } else {
          status = this.statusCode.find((obj) => {
            return obj.workStatus === item.workStatus;
          });
        }
      }

      return "color: " + status.color + "; margin: 0;";
    },
    async initialize() {
      this.loading = true;

      if (this.$store.getters.getWorkList.length === 0) {
        const token = this.$session.get("token");
        const result = await this.$store.dispatch("getWorkList", { axios: this.$axios, config, token });
        if (result.status !== 200) {
          this.error = true;
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
          this.messageBox.allowCancel = false;
        }
      }

      const eventCode = localStorage.getItem("eventCode");
      if (eventCode) {
        this.newItem();
      }

      this.loading = false;
    },
    editItem(item) {
      this.$router.push(`/platform/edit/${item.workid}`);
      // this.editedIndex = this.works.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // this.dialog = true;
      // this.viewOnly = false;
    },
    newItem() {
      this.$router.push(`/platform/new`);
      this.dialog = true;
      this.viewOnly = false;
    },
    async deleteItemDialog(item) {
      // const index = this.works.indexOf(item);
      this.editedItem = item;
      if (await this.$refs.confirm.open("", this.$t("dialogMsg.myCollection.deleteArtwork"))) {
        this.deleteItem();
      }
    },
    dismissDialog() {
      this.dialogBox = {
        state: false,
        title: "",
        content: "",
        cssClass: "error",
        destination: ""
      };
    },
    async deleteItem() {
      const token = this.$session.get("token");
      const item = this.editedItem;
      const keypair = this.$session.get("keypair");
      const result = await deleteWork(this.$axios, config, token, item.workid, keypair);
      if (result.status === 200) {
        this.$store.dispatch("updateArtwork", { axios: this.$axios, config, token, workid: item.workid });
        this.messageBox.content = this.$t("dialogMsg.myCollection.deleteArtworkSuccess");
        this.messageBox.cssClass = "message";
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.messageBox.allowCancel = false;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      } else {
        this.$store.dispatch("updateArtwork", { axios: this.$axios, config, token, workid: item.workid });
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.messageBox.allowCancel = false;
      }
    },
    viewItem(item) {
      if (item.workStatus >= 3) {
        this.$router.push(`/platform/artwork/${item.workid}`).catch((e) => e);
        return;
      } else {
        this.$router.push(`/platform/mycollection/${item.workid}`).catch((e) => e);
        this.editedIndex = this.works.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.viewOnly = true;
        this.dialog = true;
        return;
      }
    },
    publishDialog(item) {
      this.itemToPublish = item;
      this.toPublish = true;
    },
    async publish(item) {
      this.$store.commit("showProgressLine", true);
      const token = this.$session.get("token");
      const keypair = this.$session.get("keypair");
      const workid = item.workid;

      if (item.workStatus === 0) {
        const payload = {
          toPublish: true,
          publicKey: keypair.publicKey,
          privateKey: keypair.privateKey
        };

        const result = await publishToBazaar(this.$axios, config, token, workid, payload);

        if (result.status === 200) {
          this.$store.dispatch("updateArtwork", { axios: this.$axios, config, token, workid: item.workid });
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        }
      }
      this.$store.commit("showProgressLine", false);
    },
    open(props) {
      this.openIdx = props.index;
      props.expanded = !props.expanded;
    },
    async close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    formatDate(date) {
      let time = new Date(date);
      return time.toLocaleString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit"
      });
    },
    translateArray(item) {
      const content = this.$t(item);
      const result = content.replace(/['"]+/g, "").replace(/['“]+/g, "").replace(/['”]+/g, "").split(",");
      return result;
    },
    async revertToPrivate(item) {
      const token = this.$session.get("token");
      const keypair = this.$session.get("keypair");
      const payload = {
        toPublish: false,
        publicKey: keypair.publicKey,
        privateKey: keypair.privateKey
      };

      const result = await publishToBazaar(this.$axios, config, token, item.workid, payload);

      if (result.status === 200) {
        item.workStatus = 0;
        this.$store.commit("updateWorkByID", item);
        this.$store.dispatch("updateArtwork", { axios: this.$axios, config, token, workid: item.workid });
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
      }
    },
    blockchainUpdateWait(workid) {
      return retrieveLastUpdate(workid, this.datenow);
    },
    allowActions(item) {
      let address = this.$store.state.userProfile.address;
      if (item.licenseEndAt !== 0 && item.creatorAddress !== address) {
        return false;
      }
      return true;
    },
    isLicensee(item) {
      if (Object.hasOwnProperty.call(item, "licensees")) {
        const licensee = item.licensees.some((elem) => {
          return elem === this.$store.state.userProfile.address;
        });

        return licensee;
      } else {
        return false;
      }
    },
    allowDelete(item) {
      // Check if licensed
      if (Object.hasOwnProperty.call(item, "licensees")) {
        return false;
      }

      if (this.blockchainUpdateWait(item.workid) < 100) {
        return false;
      }

      return true;
    },
    allowEdit(item) {
      if (this.blockchainUpdateWait(item.workid) < 100) {
        return false;
      }
      if (this.isLicensee(item)) {
        return false;
      }
      return true;
    },
    allowRevertToPrivate(item) {
      if (this.isLicensee(item)) {
        return false;
      }
      if (this.blockchainUpdateWait(item.workid) < 100) {
        return false;
      } else {
        return item.workStatus !== 0 && item.workStatus !== 3 && !item.isHidden;
      }
    },
    allowPublish(item) {
      if (this.isLicensee(item)) {
        return false;
      }
      if (this.blockchainUpdateWait(item.workid) < 100) {
        return false;
      }
      return !this.allowRevertToPrivate(item);
    },
    allowView() {
      return true;
    },
    licensed(item) {
      if (Object.hasOwnProperty.call(item, "licensees")) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.speech-bubble {
  position: relative;
  background: white;
  border-radius: 0.4em;
  padding: 6px;
  p {
    margin: 0;
    padding-right: 4px;
  }

  .v-btn {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    img {
      margin-left: 2px;
      margin-right: 6px;
    }
    .v-icon {
      margin-left: 2px;
      margin-right: 3px;
    }
  }
}
</style>
