<template>
  <v-col class="pa-0">
    <div style="width: 100%">
      <h5>{{ $t("createEvent") }}</h5>
    </div>

    <div class="event mb-5" v-if="editable">
      <div @click="resetEventCode" style="margin-top: 10px">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <switch-control v-model="joinEvent" color="#63a3c3" />
            </div>
          </template>
          {{ $t("createEventHover") }}
        </v-tooltip>
      </div>
      <v-combobox
        v-model="eventCode"
        :error-messages="eventCodeErrors"
        ref="eventCodeInput"
        @input="changeEventCode"
        @change="checkEventCode"
        @blur="checkEventCode"
        @keyup.enter="checkEventCode"
        outlined
        dense
        hide-details="auto"
        :label="$t('createEventCode')"
        class="ma-0 pa-0"
        data-cy="eventCode"
        :items="eventCodeHistory.filter((e) => e)"
        :loading="loading"
        :disabled="loading || !joinEvent"
        clearable
      >
        <template v-slot:item="{ item }">
          <div class="my-2">
            <h5>
              <event-name-from-event-code :eventCode="item" :key="item"></event-name-from-event-code>
            </h5>
            <span>{{ item }}</span>
          </div>
        </template>
      </v-combobox>
      <!-- <v-btn
        v-if="joinEvent"
        @click="checkEventCode"
        class="ml-2"
        color="secondary"
        rounded
        depressed
        fab
        height="40px"
        width="40px"
        data-cy="eventCodeSubmit"
        ><v-icon>mdi-check</v-icon></v-btn
      > -->
    </div>
    <v-slide-y-transition :group="true">
      <div v-for="(event, i) in eventNameArray" :key="i" class="eventName" :data-cy="`eventName-${i}`">
        {{ event }}
        <v-btn icon @click="removeEvent(event)"><v-icon v-if="editable"> mdi-close </v-icon></v-btn>
      </div>
    </v-slide-y-transition>
    <div v-if="eventNameArray.length === 0 && !editable" style="color: #bbbbbb">
      <p>
        <i>{{ $t("createEventNoEvent") }}</i>
      </p>
    </div>
  </v-col>
</template>

<script>
import SwitchControl from "@/components/Common/SwitchControl.vue";
import { checkEvent } from "../../services/workService";

import config from "../../config";
import EventNameFromEventCode from "./eventNameFromEventCode.vue";
export default {
  components: {
    SwitchControl,
    EventNameFromEventCode
  },
  data: (vm) => ({
    eventCodeMatch: false,
    eventCodeChecked: false,
    eventNotYetStart: false,
    eventAlreadyExist: false,
    eventEnded: false,
    eventName: null,
    eventAlert: false,
    joinEvent: false,
    eventCode: "",
    eventNameArray: vm.modalValue,
    loading: false,
    updated: false
  }),
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    value: {
      type: Array,
      default: () => []
    },
    eventNames: {
      type: Array
    }
  },
  created() {
    if (this.eventNames) {
      this.eventNameArray = this.eventNames;
    } else {
      this.eventNameArray = [];
    }

    if (this.eventNames && this.eventNames.length > 0) {
      this.joinEvent = true;
    }
    const eventCode = localStorage.getItem("eventCode");

    if (eventCode) {
      this.eventNameArray = [];
      this.joinEvent = true;
      this.eventCode = eventCode;
      this.checkEventCode();
      // localStorage.removeItem("eventCode");
    }
  },
  beforeDestroy() {
    localStorage.removeItem("eventCode");
  },
  computed: {
    eventCodeErrors() {
      const errors = [];
      if (this.eventCode === "" && this.value.length > 0) {
        return errors;
      }
      !this.eventCodeMatch &&
        this.eventCodeChecked &&
        this.eventCode !== null &&
        errors.push(this.$t("errorMsg.newArtwork.wrongEventCode"));

      this.eventNotYetStart && errors.push(this.$t("errorMsg.newArtwork.notStartedEventCode"));

      this.eventAlreadyExist && errors.push(this.$t("errorMsg.newArtwork.eventCodeAlreadyExist"));

      this.eventEnded && errors.push(this.$t("errorMsg.newArtwork.completedEventCode"));
      return errors;
    },
    eventCodeHistory() {
      const roleInfo = this.$store.state.userProfile.roleInfo;
      if (roleInfo && Object.hasOwnProperty.call(roleInfo, "eventCodeHistory")) {
        return roleInfo.eventCodeHistory.filter((e) => !this.value.find((elem) => e === elem));
      } else {
        return [];
      }
    }
  },
  methods: {
    async checkEventCode() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      if (!this.eventCode) {
        this.loading = false;
        return;
      }

      if (this.eventCode.trim().length === 0) {
        this.loading = false;
        return;
      }

      let eventCodeExists = this.value.some((elem) => {
        return elem.trim() === this.eventCode.trim();
      });
      if (eventCodeExists) {
        this.eventAlreadyExist = true;
        this.loading = false;
        return;
      }
      const result = await checkEvent(this.$axios, config, this.eventCode.trim());

      if (result.status === 200) {
        let eventValidDate = this.checkEventCodeDate(result.data);
        if (eventValidDate) {
          let eventCodeArray = this.value;
          eventCodeArray.push(this.eventCode.trim());
          this.$store.dispatch("addEventCodeToUserInfo", {
            axios: this.$axios,
            config: config,
            eventCode: this.eventCode,
            token: this.$session.get("token")
          });
          this.eventNameArray.push(result.data.eventName);
          this.eventCodeMatch = true;
          this.eventCodeChecked = false;
          this.eventCode = "";
          this.updated = false;
          this.$emit("input", eventCodeArray);
        }
      } else if (result.status === 404) {
        this.eventCodeChecked = true;
        this.eventCodeMatch = false;
        this.eventName = "";
        this.eventAlert = false;
      }

      this.loading = false;
      this.$refs.eventCodeInput.blur();
    },
    resetEventCode() {
      this.eventCode = "";
      this.eventNameArray = [];
      let eventCodeArray = [];
      this.eventName = null;
      this.updated = false;
      this.$emit("input", eventCodeArray);
    },
    changeEventCode() {
      this.updated = true;
      this.eventCodeMatch = false;
      this.eventAlreadyExist = false;
      this.eventName = null;
    },
    checkEventCodeDate(data) {
      this.eventNotYetStart = false;
      this.eventEnded = false;
      const now = new Date().getTime();
      if (data.effectiveDate > now) {
        this.eventNotYetStart = true;
        return false;
      } else if (data.expiryDate < now) {
        this.eventEnded = true;
        return false;
      } else {
        return true;
      }
    },
    removeEvent(eventName) {
      let i = this.eventNameArray.findIndex((e) => {
        return eventName === e;
      });
      let eventCode = this.value[i];
      this.eventNameArray = this.eventNameArray.filter((e) => {
        return e !== eventName;
      });
      let eventCodeArray = this.value;
      eventCodeArray = eventCodeArray.filter((e) => {
        return e !== eventCode;
      });
      this.$emit("input", eventCodeArray);
    },
    async eventNameFromEventCode(eventCode) {
      const result = await checkEvent(this.$axios, config, eventCode.trim());
      return result.data.eventName;
    }
  }
};
</script>
