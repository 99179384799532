let categories = {
  Images: [
    "Abstract",
    "Aerial",
    "Animal",
    "Architecture",
    "Astrophotography",
    "Avian",
    "Black and White",
    "Cityscape",
    "Current Events",
    "Decisive Moment",
    "Defocused",
    "Digital Drawings",
    "Drawings",
    "Documentary",
    "Emotive",
    "Expression",
    "Family",
    "Fashion",
    "Film",
    "Fine Art",
    "Food",
    "Glamour",
    "HDRI (High Dynamic Range Imaging)",
    "Humorous",
    "ICM (intentional camera movement)",
    "Industrial",
    "Infrared",
    "Interior",
    "Journalism",
    "Landscape",
    "Lomo",
    "Macro",
    "Nature",
    "Nude",
    "Others",
    "Panoramas/Mosaics",
    "Performance",
    "Pinhole",
    "Portrait",
    "Product",
    "Publicity",
    "Random",
    "Recycled Art",
    "Rough Camera",
    "Rural",
    "Sea and Sand",
    "Sky",
    "Snapshot",
    "Sports",
    "Still Life",
    "Stock",
    "Street Photography",
    "Suburban",
    "Swimsuit",
    "Tourist",
    "Travel",
    "Underwater",
    "Urban",
    "Vehicle",
    "Vintage",
    "Weather",
    "Wedding"
  ],

  Writings: [
    "Autobiography",
    "Comics",
    "Essay",
    "Fact",
    "Fiction",
    "Forecast",
    "History",
    "Memory",
    "Message to Others ",
    "Lyric & Melody",
    "Philosophy",
    "Poetry",
    "Random",
    "Religion",
    "Report",
    "Research",
    "Script",
    "Science",
    "Slangs",
    "Slogan"
  ]
};

export default categories;
